import React from 'react';
import { WhatWeDoBody } from '@containers';
import { Layout } from '@components';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo';

const WhatWeDo = ({ data }) => {
  if (!data?.allMarkdownRemark?.edges?.[0]) {
    return '';
  }
  return (
    <Layout>
      <WhatWeDoBody pageData={data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter} />
    </Layout>
  );
};

export default WhatWeDo;
export const Head = ({ data }) => {
  return <SEO {...data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.seo} />;
};
export const whatWeDoPageQuery = graphql`
  query WhatWeDoPageQuery {
    allMarkdownRemark(filter: { frontmatter: { containerPath: { eq: "whatWeDoBody/WhatWeDoBody" } } }) {
      edges {
        node {
          frontmatter {
            containerPath
            whatWeDoHeroContainer {
              heroBg
              heroBgIcon
              pageName
              title
            }
            whatWeDoSlider {
              slides {
                slideHeading
                slideText
                slideImage
              }
            }
            whatWeDoTechStack {
              techBgImage
              heading
              text
              techImage
            }
            whatWeDoTextArea {
              heading1
              text1
              itemRowIcon
            }
            seo {
              title
              favicon
              meta {
                name
                content
              }
            }
          }
        }
      }
    }
  }
`;
